html {
    scroll-behavior: smooth;
  }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #2e3132;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

:root {
    --yellow: #f7b72f;
    --lightgray: #f6f6f6;
    --midgray: #e5e5e5;
    --gray: #3e3e3d;
    --black: #0e0d12;
}

/* .navbar {
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    padding: 12px 32px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: space-between;

    top: 0px;
    position: sticky;
    z-index: 100;

    box-shadow: 0px 2px 8px rgba(0,0,0,0.2);
} */
/* .navbar div {
    font-family: "Lato",sans-serif;
    font-weight: bold;
    font-size: 19px;
} */
/* .navbar img {
    width: 120px;
} */
/* .navbar ul {
    margin: 0px;
    display: flex;
    width: 55%;
} */
/* .navbar li {
    font-family: "Montserrat",sans-serif;
    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
    list-style: none;
    margin: 0;
} */
/* .navbar li a {
    padding: 4px 12px;
} */
/* .navbar a {
    text-decoration: none;
    color: #333;
    transition: color 0.2s;
} */

/* .navbar a:hover {
    color: var(--yellow);
} */


.projects {
    font-family: "Lato", sans-serif;
}
.project .heading {
    text-align: center;
    font-size: 20px;
    padding: 20px;
}
.project .heading .year {
    font-family: "Montserrat",sans-serif;
    color: #909193;

}
.project {
    padding-bottom: 45px;
}
.project .images {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.project .images img {
    width: 45%;
    margin: 5px 0;
    transition: all 0.2s;
}

.project .images img:hover {
    transform: scale(1.08);
    border-radius: 5px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.4);
}

.banner {
    text-align: center;
    font-size: 22px;
    padding: 60PX;
    background-color: rgb(247, 183, 47);
}


.contact .map{
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    height: 400px;
    position: relative;
}
.contact #embedmap-canvas {
    height: 400px; 
    width: 100%;
}
.contact iframe {
    height: 400px;
    width: 100%;
    border: 0;
}
.contact img {
    max-width: none!important;
    background: none!important;
    font-size: inherit;
    font-weight: inherit;
}
.contact #contactData {
    position: absolute;
    width: 350px;
    height: inherit;
    background-color: var(--yellow);
    z-index: 1;
    top: 0;
    right: 8%;

    padding-top: 30px;
    box-sizing: border-box;
    color: white;
    font-family: "Lato", sans-serif;
}
.contact .contactPoint {
    display: flex;
    width: 70%;
    margin: 25px auto;
}
.contact .contactPoint > div {
}
.contact .contactPoint .iconContainer {
    width: 18%;
    text-align: left;
    margin-right: 10px;
}
.contact .contactPoint .textContainer {
    width: 82%;
    text-align: left;
    white-space: pre-line;
}
.contact .contactPoint .heading {
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 16pt;
}
.contact .contactPoint .subheading {
    line-height: 18pt;
}
.iconContainer .svg-inline--fa {
    background: #e6a620;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    padding: 10px;
    border-radius: 50px;
}


/* .values {
    padding: 60px 20px;
} */
/* .values .cardWrapper {
    display: flex;
    justify-content: space-around;
} */
/* .values .aboutUs {
    display: flex;
    justify-content: space-around;
    align-items: center;
} */
/* .values .aboutUs div {
    margin: 0 20px;
} */
/* .values .aboutUs #quote {
    line-height: 32px;
    font-size: 22px;
    width: 40%;
    text-align: center;
    white-space: pre-line;
    text-shadow: 1px 1px 2px rgba(100, 100, 100, 0.2);
    color: var(--gray);
} */
/* .values .aboutUs #quote .name {
    font-family: "Bradley Hand", sans-serif;
    margin-bottom: -8px;
} */
/* .values .aboutUs #quote .nameDetail {
    font-size: 14px;
    margin-top: 5px;
    font-weight: 200;
} */


 
/* .flip-card {
    width: 200px;
    height: 220px;
    perspective: 1000px;
    margin: 20px;
} */
/* .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border: 2px solid var(--midgray);
    font-size: 17px;
} */

/* .flip-card-inner:hover {
    border: 2px solid var(--yellow);
} */
  
/* .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
} */

/* .flip-card-front {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      font-weight: bold;
} */
  
/* .flip-card-front, .flip-card-back {
    position: absolute;
    padding: 15px 12px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
} */
  
/* .flip-card-front {
    color: black;
} */
  
/* .flip-card-back {
    color: var(--gray);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateY(180deg);
 } */


/* .flip-card .svg-inline--fa {
    width: 50px;
    height: 50px;
    color: var(--yellow);
} */



@media screen and (max-width: 750px) {
    .project .images img {
        width: 80%;
    }
    .navbar #phoneNumber {
        display: none;
        color: var(--yellow);
    }

    .contact #contactData {
        position: relative;
        right: 0;
        width: 100%;
        height: auto;
        padding-top: 0;
        /* width: 350px; */
    }
    .contact #contactData .contactPoint {
        width: 85%;
    }
    .contact .map {
        height: auto;
        display: flex;
        flex-direction: column;
    }
    
  }
